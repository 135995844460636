import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [2,3,4];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/embed/scenes/[id=assetid]": [~24],
		"/(app)/forgot": [17,[2]],
		"/(app)/join": [~18,[2]],
		"/(app)/(legal)/license": [13,[2,5]],
		"/(app)/login": [~19,[2]],
		"/(app)/(legal)/privacy": [14,[2,5]],
		"/(app)/reset": [~20,[2]],
		"/(app)/(categories)/scenes": [~7,[2,3]],
		"/(app)/(categories)/scenes/[slug=assetid]": [~8,[2]],
		"/(app)/(categories)/scripts": [~9,[2,3]],
		"/(app)/(categories)/scripts/[slug=assetid]": [~10,[2,4]],
		"/(app)/(categories)/scripts/[slug=assetid]/changelog": [~11,[2,4]],
		"/(app)/(categories)/scripts/[slug=assetid]/manual": [~12,[2,4]],
		"/(app)/studio": [21,[2]],
		"/(app)/subscribe": [22,[2]],
		"/(app)/support": [23,[2]],
		"/(app)/(legal)/terms": [15,[2,5]],
		"/(app)/[user=username]": [~16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';